import React, {useState, useEffect, useRef} from 'react'

import classes from './PopupNotif.module.css'
import Popup from '@/components/shared/Popup/Popup';
import PopupsManager from 'react-sdk/PopupsManager';
import { POPUPS } from '@/js/AppState';
import clsx from 'clsx';
import Texts from 'react-sdk/Texts';
import SoundPlayer from 'react-sdk/SoundPlayer';
import Rays from '@/components/shared/Rays/Rays';
import { FaChevronLeft, FaChevronRight, FaCompress, FaExpand } from 'react-icons/fa6';
import Button from '@/components/shared/Button/Button';
import Analytics from 'react-sdk/Vertx/Analytics';



/**
 * @typedef NotifPopupOptions
 * @type {object}
 * @property {string} sfx
 * @property {string} surtitle
 * @property {string} title
 * @property {string} image_url exemple : "data/images/picture.png"
 * @property {string} text
 * @property {string} color
 * @property {boolean} rays
 * @property {function} onClose
 * @property {string} content_image_url
 * @property {ReactNode} Buttons
 * @property {'left'|'center'|'right'|'justify'} textAlign
 * @property {Array<NotifPopupOptions>} Pages
 *
 */

const PopupContent = ({data}) => {
  const { surtitle, title , text, textAlign, content_image_url, Buttons} = data

  console.log(data)

  const [showFSImage, setShowFSImage] = useState(false);

  function handleClick() {

    setShowFSImage(true)
  }

  function handleCompress() {
    setShowFSImage(false)
  }

  useEffect(() => {

    const evt = title + " - " + surtitle

    Analytics.contentEventShow(evt)

    return () => {
      Analytics.contentEventHide(evt)
    }

  }, []);
  return (
    <>
      {surtitle && <div className={classes.surtitle} dangerouslySetInnerHTML={{__html: surtitle}}></div>}
      {title && <div className={classes.title}>{title}</div>}

      {content_image_url &&
      <div className={classes.content_image}>
        <Button
        className={classes.content_image_fs_btn}
        variant='secondary small'
        onClick={handleClick}
        icon={<FaExpand />} />
        <img src={content_image_url} />
      </div>}

      {text &&
      <div className={classes.text_ctnr} >
        <div className={classes.text} style={{textAlign}} dangerouslySetInnerHTML={{__html: Texts.parseMarkdown(text, {noParagraph: true})}} />
      </div>}
      {Buttons && <div className={classes.btns}><Buttons /></div>}

      {showFSImage &&
      <div className={classes.fs_image}>
        <Button
        className={classes.content_image_fs_btn}
        onClick={handleCompress}
        icon={<FaCompress />} />

        <img src={content_image_url} />
      </div>}
    </>
  )
}


const PopupNotif = () => {

  /** @type {NotifPopupOptions} */
  const data = PopupsManager.getData(POPUPS.NOTIF)
  const { image_url, rays, color, onClose, sfx, Pages} = data

  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    if(sfx) {
      SoundPlayer.playSound(sfx)
    }
  }, []);

  const showPrevBtn = Pages && pageIndex > 0
  const showNextBtn = Pages && pageIndex < Pages.length - 1

  return (
    <Popup className={clsx(classes.PopupNotif, image_url && classes.with_image)} style={{"--bg-color": color}} onClose={onClose}>
      {rays && <div className={classes.rays}><Rays /></div>}

      {image_url &&
      <div className={classes.image}>
        <img src={image_url} />
      </div>}

      {!Pages && <PopupContent data={data} />}


      {Pages && <PopupContent data={Pages[pageIndex]} />}
      {showPrevBtn && <Button onClick={() => setPageIndex(i => i-1)} className={classes.prev_page_btn} icon={<FaChevronLeft />} />}
      {showNextBtn && <Button onClick={() => setPageIndex(i => i+1)} className={classes.next_page_btn} icon={<FaChevronRight />} />}

    </Popup>
  );
};



export default PopupNotif;
